@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@font-face {
	font-family: 'Abel Pro';
	src: url(/static/media/abelpro.fe905822.eot);
	src: url(/static/media/abelpro.fe905822.eot?#iefix) format('embedded-opentype'), url(/static/media/abelpro.06e0aaaf.woff2) format('woff2'), url(/static/media/abelpro.4716b740.woff) format('woff'),
		url(/static/media/abelpro.481c666b.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Abel Pro';
	src: url(/static/media/abelpro-bold.1c6301a7.eot);
	src: url(/static/media/abelpro-bold.1c6301a7.eot?#iefix) format('embedded-opentype'), url(/static/media/abelpro-bold.1dd66be4.woff2) format('woff2'), url(/static/media/abelpro-bold.e085bbc1.woff)
		format('woff'), url(/static/media/abelpro-bold.95aead61.ttf) format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Abel';
	src: url(/static/media/abel-regular_0_hun.b91befe4.eot);
	src: url(/static/media/abel-regular_0_hun.b91befe4.eot?#iefix) format('embedded-opentype'), url(/static/media/abel-regular_0_hun.326b6afb.woff2) format('woff2'),
		url(/static/media/abel-regular_0_hun.ee217de3.woff) format('woff'), url(/static/media/abel-regular_0_hun.1df0aa9f.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'dinpro';
	src: url(/static/media/dinpro-regular-3-webfont.eef440f5.eot);
	src: url(/static/media/dinpro-regular-3-webfont.eef440f5.eot#iefix) format('embedded-opentype'), url(/static/media/dinpro-regular-3-webfont.f6fa7aaf.woff2) format('woff2'),
		url(/static/media/dinpro-regular-3-webfont.046a1b95.woff) format('woff'), url(/static/media/dinpro-regular-3-webfont.68918192.ttf) format('truetype'),
		url(/static/media/dinpro-regular-3-webfont.b744c973.svg#dinproregular) format('svg');
	font-weight: normal;
}

@font-face {
	font-family: 'dinpro';
	src: url(/static/media/dinpro-medium_0-webfont.e2e23a82.eot);
	src: url(/static/media/dinpro-medium_0-webfont.e2e23a82.eot#iefix) format('embedded-opentype'), url(/static/media/dinpro-medium_0-webfont.a4f28068.woff2) format('woff2'),
		url(/static/media/dinpro-medium_0-webfont.d140d62f.woff) format('woff'), url(/static/media/dinpro-medium_0-webfont.2968a3e6.ttf) format('truetype'),
		url(/static/media/dinpro-medium_0-webfont.5f5f1e20.svg#dinpromedium) format('svg');
	font-weight: bold;
}

@font-face {
	font-family: FontAwesome;
	src: url(/static/media/fontawesome-webfont.674f50d2.eot);
	src: url(/static/media/fontawesome-webfont.674f50d2.eot?#iefix&v=4.5.0) format('embedded-opentype'), url(/static/media/fontawesome-webfont.af7ae505.woff2) format('woff2'),
		url(/static/media/fontawesome-webfont.fee66e71.woff) format('woff'), url(/static/media/fontawesome-webfont.b06871f2.ttf) format('truetype'),
		url(/static/media/fontawesome-webfont.acf3dcb7.svg#fontawesomeregular) format('svg');
	font-weight: 400;
	font-style: normal
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 100% auto;
  background-color: rgb(243, 243, 243);
}

#root {
  height: 100vh;
}

.MuiListItemText-dense {
  margin: 0 !important;
}
@media screen and (max-width: 600px) {
  #root {
    min-height: -webkit-fill-available;
  }

  #root >:nth-child(2) {
    top: 3rem !important;
  }
}

@media screen and (max-width: 960px) {
  label.MultiLineLabel {
    margin-top: -2em;
  }

  div.MultiLineLabel {
    padding-top: 2em;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

