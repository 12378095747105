@font-face {
	font-family: 'Abel Pro';
	src: url("../assets/fonts/abelpro.eot");
	src: url("../assets/fonts/abelpro.eot?#iefix") format('embedded-opentype'), url("../assets/fonts/abelpro.woff2") format('woff2'), url("../assets/fonts/abelpro.woff") format('woff'),
		url("../assets/fonts/abelpro.ttf") format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Abel Pro';
	src: url("../assets/fonts/abelpro-bold.eot");
	src: url("../assets/fonts/abelpro-bold.eot?#iefix") format('embedded-opentype'), url("../assets/fonts/abelpro-bold.woff2") format('woff2'), url("../assets/fonts/abelpro-bold.woff")
		format('woff'), url("../assets/fonts/abelpro-bold.ttf") format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Abel';
	src: url("../assets/fonts/abel-regular_0_hun.eot");
	src: url("../assets/fonts/abel-regular_0_hun.eot?#iefix") format('embedded-opentype'), url("../assets/fonts/abel-regular_0_hun.woff2") format('woff2'),
		url("../assets/fonts/abel-regular_0_hun.woff") format('woff'), url("../assets/fonts/abel-regular_0_hun.ttf") format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'dinpro';
	src: url("../assets/fonts/dinpro-regular-3-webfont.eot");
	src: url("../assets/fonts/dinpro-regular-3-webfont.eot#iefix") format('embedded-opentype'), url("../assets/fonts/dinpro-regular-3-webfont.woff2") format('woff2'),
		url("../assets/fonts/dinpro-regular-3-webfont.woff") format('woff'), url("../assets/fonts/dinpro-regular-3-webfont.ttf") format('truetype'),
		url("../assets/fonts/dinpro-regular-3-webfont.svg#dinproregular") format('svg');
	font-weight: normal;
}

@font-face {
	font-family: 'dinpro';
	src: url("../assets/fonts/dinpro-medium_0-webfont.eot");
	src: url("../assets/fonts/dinpro-medium_0-webfont.eot#iefix") format('embedded-opentype'), url("../assets/fonts/dinpro-medium_0-webfont.woff2") format('woff2'),
		url("../assets/fonts/dinpro-medium_0-webfont.woff") format('woff'), url("../assets/fonts/dinpro-medium_0-webfont.ttf") format('truetype'),
		url("../assets/fonts/dinpro-medium_0-webfont.svg#dinpromedium") format('svg');
	font-weight: bold;
}

@font-face {
	font-family: FontAwesome;
	src: url("../assets/fonts/fontawesome-webfont.eot?v=4.5.0");
	src: url("../assets/fonts/fontawesome-webfont.eot?#iefix&v=4.5.0") format('embedded-opentype'), url("../assets/fonts/fontawesome-webfont.woff2?v=4.5.0") format('woff2'),
		url("../assets/fonts/fontawesome-webfont.woff?v=4.5.0") format('woff'), url("../assets/fonts/fontawesome-webfont.ttf?v=4.5.0") format('truetype'),
		url("../assets/fonts/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular") format('svg');
	font-weight: 400;
	font-style: normal
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
