body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 100% auto;
  background-color: rgb(243, 243, 243);
}

#root {
  height: 100vh;
}

.MuiListItemText-dense {
  margin: 0 !important;
}
@media screen and (max-width: 600px) {
  #root {
    min-height: -webkit-fill-available;
  }

  #root >:nth-child(2) {
    top: 3rem !important;
  }
}

@media screen and (max-width: 960px) {
  label.MultiLineLabel {
    margin-top: -2em;
  }

  div.MultiLineLabel {
    padding-top: 2em;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
